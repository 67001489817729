export const TAGGED_PRODUCTS = [
  {
    paths: [
      '/shop/prescription-glasses/black-cherry',
      '/shop/blue-light-filter-glasses/black-cherry',
      '/shop/prescription-sunglasses/black-cherry',
      '/shop/non-prescription-sunglasses/black-cherry',
      '/shop/frames/black-cherry',
      '/shop/spare-parts/black-cherry',
      '/shop/reading-glasses/black-cherry',
    ],
    backgroundColor: '#FF8700',
    textColor: '#FFFFFF',
    text: 'New',
    withStarIcon: false,
    availableRegions: ['au', 'nz', 'international', 'ca']
  },
  {
    paths: [
      '/shop/prescription-glasses/bloody-mary',
      '/shop/blue-light-filter-glasses/bloody-mary',
      '/shop/prescription-sunglasses/bloody-mary',
      '/shop/sunglasses/bloody-mary',
      '/shop/non-prescription-sunglasses/bloody-mary',
      '/shop/frames/bloody-mary',
      '/shop/spare-parts/bloody-mary',
      '/shop/reading-glasses/bloody-mary',
    ],
    backgroundColor: '#FF8700',
    textColor: '#FFFFFF',
    text: 'Back in Stock',
    withStarIcon: false,
    availableRegions: ['au', 'nz', 'international', 'ca']
  },
];
